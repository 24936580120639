<script lang="ts" setup>
import type { TypeNavigationSubpage, TypePage } from "~/types/contentful";
import { pushDataLayerEvent } from "~/lib/client-data-layer";

const props = defineProps<{
  companyUrl?: string;
  fields: Omit<
    TypeNavigationSubpage<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"],
    "items"
  > & {
    items: {
      fields: Pick<
        TypePage<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"],
        "title" | "slug" | "parent"
      >;
    }[];
    activeItemIndex?: number;
  };
}>();

const menuVisible = ref(false);

const activeItemTitle = computed(() => {
  return props.fields.items[props.fields.activeItemIndex ?? 0]?.fields.title;
});

const activeItemSlug = computed(() => {
  return props.fields.items[props.fields.activeItemIndex ?? 0]?.fields.slug?.split("/").pop();
});

const isFullProfile = computed(() => (activeItemTitle.value === 'Downloads' || activeItemTitle.value == 'Company Info'))

const isLocked = (page: TypePage<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">) =>
  page.fields.access?.includes("Member") && !useUser().value;

const downloadEvent = () => {
  pushDataLayerEvent("evDownloadAssessment", { title: "Assessment", company: props.fields.badge, project: activeItemTitle.value });
};
</script>

<template>
  <Container
    class="sub-navigation sticky top-0 z-20 border-b border-b-ui-grey2 bg-white text-sm print:hidden"
    container-class="max-md:px-0"
    :class="{
      'sub-navigation--top-level': !fields.depth,
      'sub-navigation--lower-level': fields.depth,
    }"
    :style="{ top: `${48 * (fields.depth || 0)}px` }"
  >
    <button
      class="flex w-full items-start justify-between border-0 border-b-4 border-b-light-royal-blue bg-white px-3 py-[12px] text-sm font-semibold text-light-royal-blue md:hidden"
      @click="() => (menuVisible = !menuVisible)"
    >
      {{ activeItemTitle ? activeItemTitle : "Menu" }}
      <NuxtIcon
        size="20"
        name="fairr:arrow"
      />
    </button>
    <div
      class="nav-wrapper flex justify-between gap-2"
      :class="{
        'max-md:hidden': !menuVisible && (fields.badge || !fields.depth),
      }"
    >
      <nav class="nav-tabs flex items-center overflow-x-scroll">
        <div class="flex items-center md:gap-4">
          <NuxtLink
            v-for="(item, index) in fields.items"
            :id="'tab'+item.fields.title.replaceAll(' ', '')"
            :key="item.fields.slug"
            class="whitespace-nowrap max-md:px-3"
            :class="{
              active:
                index === fields.activeItemIndex
                || (item.fields.parent
                  && getUrl(
                    item as TypePage<'WITHOUT_UNRESOLVABLE_LINKS', 'en-GB'>,
                  ) === useRoute().path),
              locked: isLocked(
                item as TypePage<'WITHOUT_UNRESOLVABLE_LINKS', 'en-GB'>,
              ),
            }"
            :to="
              item.fields.parent
                ? getUrl(
                  item as TypePage<'WITHOUT_UNRESOLVABLE_LINKS', 'en-GB'>,
                )
                : item.fields.slug
            "
            @click="() => (menuVisible = false)"
          >
            <span
              class="flex items-center gap-2 border-b-4 border-b-white pb-[calc(1rem-4px)] pt-3"
            >
              {{ item.fields.title }}
              <NuxtIcon
                v-if="
                  isLocked(
                    item as TypePage<'WITHOUT_UNRESOLVABLE_LINKS', 'en-GB'>,
                  )
                "
                size="16"
                name="fairr:lock"
              />
            </span>
          </NuxtLink>
        </div>
      </nav>
      <div class="hidden items-center lg:flex">
        <div class="me-3 text-xs">
          {{ fields.tag }}
        </div>

        <template v-if="props.companyUrl && useUser().value">
          <NuxtLink
            :to="props.companyUrl + '/print?p=' + (isFullProfile ? 'all' : activeItemSlug)"
            target="_blank"
            class="btn gap-2 min-w-[290px] relative right-1 !text-white"
            @click="downloadEvent"
          >
            Download {{ isFullProfile ? 'Full Profile' : 'Company Assessment' }}
            <NuxtIcon
              name="fairr:download"
              size="24"
            />
          </NuxtLink>
        </template>
      </div>
    </div>
  </Container>
</template>

<style lang="scss" scoped>
.sub-navigation {
  &--top-level {
    @apply z-20;

    nav {
      & > div {
        @apply max-md:w-full max-md:flex-col max-md:items-start;
      }
    }
  }

  &--lower-level {
    @apply sticky z-[8] overflow-x-scroll border-b border-b-ui-grey2 bg-ui-grey4 md:top-[52px] md:overflow-auto;

    .sub-navigation__trigger {
      @apply hidden;
    }

    nav {
      & > div {
        @apply max-md:gap-2;
      }
    }
  }

  a {
    &:last-child {
      @apply max-md:pr-3;
    }

    &.locked {
      @apply text-ui-grey3;
    }

    &:not(.locked) {
      &:hover,
      &.active {
        @apply text-light-royal-blue;

        span {
          @apply border-b-light-royal-blue;
        }
      }
    }
  }

  .nav-tabs::-webkit-scrollbar {
    display: none;
  }

  .nav-tabs {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}

.hiddendot {
  opacity: 0;
}
</style>
