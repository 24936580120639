<script lang="ts" setup>
import { pushDataLayerEvent } from "~/lib/client-data-layer";

type WalkthroughStep = {
  headline: string;
  text: string;
  elementIds: string;
  buttonText?: string;
  buttonLink?: string;
  noScroll?: boolean;
};

const props = defineProps<{
  steps: WalkthroughStep[];
  chapter: string;
  tourname: string;
}>();

const emit = defineEmits<(e: "walk", value: { index: number; minimized: boolean }) => void>();

const currentStepIndex = ref(0);
const minimized = ref(true);
const minimizing = ref(false);
const mouseleft = ref(1);

onBeforeMount(() => {
  addClass(".blue-dot:not(.hiddendot)", " hiddendot");
});

onMounted(() => {
  const walkthroughSeen = window.localStorage.getItem(props.tourname);

  if (!Number(walkthroughSeen)) {
    setTimeout(() => {
      show();
    }, 300);
  }

  setTimeout(setupPulsingDots, 300);
  setTimeout(() => {
    if (props.steps.length === 1 && props.steps[0]?.elementIds) {
      const blueDot = document.querySelectorAll("[data-windex='0']");
      if (blueDot[0]) {
        removeClass(blueDot, "hiddendot");
        if (!currentStep.value.noScroll) scrollToEl(blueDot[0] as HTMLElement);
      }
    }
  }, 400);
});

const currentStep = computed(() => props.steps[currentStepIndex.value] ?? { headline: "", text: "", elementIds: "", buttonLink: "", buttonText: "" });
const walkthroughChapter = computed(() => props.chapter ? props.chapter : "1");

const addClass = (selector: string, classToAdd: string) => {
  const elements = document.querySelectorAll(selector);
  elements.forEach((el) => el.className += classToAdd);
};

const removeClass = (nodeList: NodeList, classToRemove: string) => {
  nodeList.forEach((element) => {
    (element as HTMLElement).classList.remove(classToRemove);
  });
};

const show = () => {
  minimized.value = false;
  emitChange();
};

const hide = () => {
  minimized.value = true;
  addClass(".blue-dot:not(.hiddendot)", " hiddendot");
  emitChange();
};

const emitInteractedEvent = () => {
  if (props.steps.length === 1) pushDataLayerEvent("evInteractedTour", { tour: false, tourname: props.tourname });
};

const next = () => {
  if (currentStepIndex.value === 0) pushDataLayerEvent("evStartTour", { tour: true, tourname: props.tourname });
  currentStepIndex.value += 1;
  addClass(".blue-dot:not(.hiddendot)", " hiddendot");

  const blueDot = document.querySelectorAll(`[data-windex="${currentStepIndex.value}"]`);
  if (blueDot[0]) {
    removeClass(blueDot, "hiddendot");
    if (!currentStep.value.noScroll) scrollToEl(blueDot[0] as HTMLElement);
  }

  if (currentStepIndex.value === props.steps.length - 1) {
    pushDataLayerEvent("evCompletedTour", { tour: false, tourname: props.tourname });
    if (!currentStep.value.noScroll) scrollToY(0);
  }
};

const back = () => {
  currentStepIndex.value -= 1;
  addClass(".blue-dot:not(.hiddendot)", " hiddendot");

  const blueDot = document.querySelectorAll(`[data-windex="${currentStepIndex.value}"]`);
  if (blueDot[0]) {
    removeClass(blueDot, "hiddendot");
    if (!currentStep.value.noScroll) scrollToEl(blueDot[0] as HTMLElement);
  }
  if (currentStepIndex.value == 0) scrollToY(0);
};

const easeOutQuart = (t: number) => {
  return 1 - (--t) * t * t * t;
};

const scrollToY = (to: number) => {
  const start = window.scrollY;
  const time = Date.now();
  const duration = Math.abs(start - to) + 400;

  (function step() {
    const dx = Math.min(1, (Date.now() - time) / duration);
    const pos = start + (to - start) * easeOutQuart(dx) - 50;

    window.scrollTo(0, pos);

    if (dx < 1) {
      window.requestAnimationFrame(step);
    }
  })();
};

const scrollToEl = (element: HTMLElement) => {
  const box = element.getBoundingClientRect();

  const body = document.body;
  const docEl = document.documentElement;

  const scrollTop = window.scrollY || docEl.scrollTop || body.scrollTop;
  const clientTop = docEl.clientTop || body.clientTop || 0;
  const top = box.top + scrollTop - clientTop - 50;

  scrollToY(top);
};

const onMouseLeft = () => {
  mouseleft.value = 1;
};

const onMouseOver = () => {
  if (minimized.value && currentStepIndex.value == 0 && !minimizing.value && mouseleft.value) {
    mouseleft.value = 0;
    show();
  }
};

const blueDotClone = (stepIndex: number) => {
  const blueDot = document.getElementById("blueDot")?.cloneNode(true) as HTMLElement;
  blueDot.id = "";
  blueDot.textContent = stepIndex != 0 ? String(stepIndex) : "◯";
  blueDot.setAttribute("data-windex", String(stepIndex));
  const fragment = document.createDocumentFragment();
  fragment.appendChild(blueDot);
  return fragment;
};

const setupDot = (destinationElementId: string, stepIndex: number) => {
  const destinationElement = document.getElementById(destinationElementId);

  if (destinationElement) {
    destinationElement.classList.add("relative");
    if (stepIndex != 0) {
      const blueDot = blueDotClone(stepIndex);
      destinationElement.appendChild(blueDot);
    }
  }
};

const setupPulsingDots = () => {
  const stepElementsIds = props.steps.map((step) => step.elementIds);
  stepElementsIds.forEach((ids, stepIndex) => {
    if (ids) ids.split(",").forEach((id) => {
      setupDot(id, stepIndex);
    });
  });
};

const closeWalkthrough = () => {
  hide();
  window.localStorage.setItem(props.tourname, walkthroughChapter.value);
  setTimeout(() => {
    minimizing.value = true;
    currentStepIndex.value = 0;
    minimizing.value = false;
  }, 300);
};

const emitChange = () => {
  emit("walk", { index: currentStepIndex.value, minimized: minimized.value });
};
</script>

<template>
  <div
    class="walkthrough rounded bg-[#3241e1] text-left text-white print:hidden"
    :class="{ minimized }"
    @mouseover="onMouseOver"
    @mouseleave="onMouseLeft"
  >
    <div
      class="pointer absolute right-0 top-0 p-2 transition-all"
      :class="{ 'pointer-events-none opacity-0': minimized }"
      @click="closeWalkthrough"
    >
      <NuxtIcon
        name="fairr:cross"
        size="24"
      />
    </div>

    <section
      class="walkthrough__main px-4 py-3"
      :class="{ minimized }"
    >
      <h4
        v-if="currentStepIndex == 0 || currentStepIndex==steps.length-1"
        class="mb-3 mt-0"
        v-text="currentStep.headline"
      />
      <div
        v-else
        class="walkthrough__steps mb-3 flex"
      >
        <template
          v-for="(step, i) in steps"
          :key="i"
        >
          <div
            v-if="i !==0 && i != steps.length-1"
            class="font-body text-sm font-bold"
            :class="{ selected: currentStepIndex==i }"
            v-text="currentStepIndex === i ? i : '◯'"
          />
        </template>
      </div>

      <div
        v-if="currentStep.text"
        class="mb-4 text-sm"
        :class="{ 'step-text': currentStepIndex !== steps.length-1 }"
        v-html="currentStep.text"
      />

      <div
        v-if="currentStepIndex == 0 && steps.length !== 1"
        class="flex-container pt-1"
      >
        <div
          class="btn mr-1 text-sm"
          @click="next"
        >
          <b>Take the tour</b>
        </div>
      </div>

      <div
        v-else-if="currentStepIndex == steps.length - 1"
        class="walkthrough__buttons flex-container pt-1"
      >
        <NuxtLink
          v-if="currentStep.buttonLink && currentStep.buttonText"
          class="btn mr-1"
          :to="String(currentStep.buttonLink)"
          @click="closeWalkthrough(); emitInteractedEvent();"
        >
          <b>{{ currentStep.buttonText }}</b>
        </NuxtLink>
      </div>

      <div
        v-else
        class="walkthrough__buttons flex-container"
      >
        <b
          class="walkthrough__next inline-flex p-3"
          @click="back"
        >Back</b>
        <b
          class="walkthrough__next inline-flex p-3"
          @click="next"
        >Next</b>
      </div>
    </section>

    <section
      class="walkthrough__question"
      :class="{ minimized }"
    >
      <h4 class="m-0">
        ?
      </h4>
    </section>

    <div
      id="blueDot"
      class="blue-dot hiddendot font-body text-[0.8125rem] font-bold"
    >
      ◯
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .walkthrough {
    position: fixed;
    top: 144px;
    right: 20px;
    z-index: 1009;
    transition: 0.3s ease;
    overflow: hidden;
    @media (max-width: 768px) {
      right: 16px;
    }

    h4 {
      display: block;
      max-height: 64px;
      line-height: 1.2;
    }

    &__steps div {
      width: 24px;
      height: 24px;
      margin-right: 4px;
      line-height: 1.7;

      &.selected {
        border-radius: 50px;
        color: #4353ff;
        background-color: white;
        text-align: center;
        padding-top: 0px !important;
        box-shadow: 0 0 10px 0 #8e97ff;
      }
      &:not(.selected) {
        font-size: 10px;
        line-height: 0.9;
        padding: 8px;
      }
    }

    &.minimized {
      width: 40px;
      min-height: 40px;
      max-height: 40px;
      transition-delay: 0.2s;

      section {
        padding: 8px 16px 8px 15px;
      }
    }

    &__buttons {
      max-height: 64px;
      line-height: 1.4;
    }

    &__main {
      opacity: 1;
      transition: 0.2s;
      transition-delay: 0.3s;
      &.minimized {
        opacity: 0;
        pointer-events: none;
        transition-delay: 0s;
      }
      b {
        font-size: 1rem;
      }
    }
    &__question {
      position: absolute;
      top: 0;
      opacity: 0;
      transition: 0.2s;
      pointer-events: none;
      &.minimized {
        pointer-events: all;
        opacity: 1;
      }
    }
    &:not(.minimized) {
      width: 384px;
      max-width: 100vw;
      max-height: 350px;
      min-height: 147px;
      @media (max-width: 768px) {
        width: 290px;
      }
    }
    &__next {
      position: relative;
      left: -11px;
      margin-bottom: -11px;
      cursor: pointer;
      user-select: none;
    }
    .button {
      min-width: 120px;
      text-align: center;
      margin-left: 0;
    }
  }

  .modal-close-button {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 8px;
    padding-right: 8px;
    transition: 0.2s ease;
    &.minimized {
      opacity: 0;
      pointer-events: 0;
    }
  }
  .inline-flex {
    display: inline-flex;
  }
  .step-text {
    min-height: 40px;
  }
</style>

<style lang="scss">
  .hiddendot {
    opacity: 0;
  }

  .blue-dot {
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1001;
    top: -10px;
    right: 0px;
    width: 24px;
    height: 24px;
    background: #4353ff;
    border-radius: 50%;
    pointer-events: none;
    color: white;
    &:before {
      content: '';
      display: block;
      position: absolute;
      border-radius: 50%;
      z-index: -1;
      width: 56px;
      height: 56px;
      left: -16px;
      top: -16px;
      pointer-events: none;

      background-color: rgba(67, 83, 255, 0.3);
      animation: pulse 2s infinite;
      animation-delay: 0.2s;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      border-radius: 50%;
      z-index: -1;
      width: 40px;
      height: 40px;
      left: -8px;
      top: -8px;
      pointer-events: none;
      background-color: rgba(67, 83, 255, 0.6);
      animation: pulse 2s infinite;
    }
  }

  .animate-pulse {
    animation: pulse 2s infinite;
  }
  .animate-delay {
    animation-delay: 0.2s;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.6);
      box-shadow: 0 0 0 0 rgba(67, 83, 255, 0.7);
    }

    65% {
      transform: scale(1.15);
      opacity: 0.85;
    }

    85% {
      box-shadow: 0 0 0 16px rgba(67, 83, 255, 0);
    }

    100% {
      transform: scale(0.6);
      box-shadow: 0 0 0 0 rgba(67, 83, 255, 0);
    }
  }
</style>
