export const companyProfile = [
  {
    headline: "Welcome to the Coller FAIRR Protein Producer Index 2024 - Supplier analysis",
    text: "Here you can see more detail about the company's suppliers.",
    elementIds: "",
  },
  // {
  //   headline: "",
  //   text: "In the table, view the Index score and key information for each supplier.",
  //   elementIds: "walkthroughTable",
  // },
  {
    headline: "",
    text: "We have included the start date of their supplier relationship in the table.",
    elementIds: "thYear",
  },
  {
    headline: "",
    text: "Also in the table, you can see the supplier revenue share generated by the company.",
    elementIds: "thRevenue",
  },
  {
    headline: "",
    text: "You can download the supplier analysis data file.",
    elementIds: "supplyDownloads",
  },
  {
    headline: "",
    text: "You can use the filter to view and compare the supplier's factor and KPI scores.",
    elementIds: "sPerformance",
  },
  {
    headline: "",
    text: "You can also compare all risk and opportunity factors for each supplier by using the heat map.",
    elementIds: "supplyHeatmap",
  },
  {
    headline: "",
    text: "You can click on a supplier name to view their company profile page and access an in depth analysis of their Index 2024 score.",
    elementIds: "walkthroughCompany",
  },
  {
    headline: "Start using the 2024 Index",
    text: "You can now close this window or view the prompts again.",
    elementIds: "",
    buttonText: "Protein Producer Index",
    buttonLink: "/tools/protein-producer-index#company-ranking",
  },
];

export const tool = [
  {
    headline: "Welcome to the Supply Chain Analysis tool",
    text: "This page shows which Index companies supply some of the world's largest retailers, food manufacturers and restaurants that are covered by FAIRR's engagement workstreams.<br/><br/>It highlights the relationship between protein producers and these downstream companies, which can in turn helps identify risks and opportunities within the protein supply chain.",
    elementIds: "",
  },
  {
    headline: "",
    text: "You can search for the name of a retailer, food manufacturer or restaurant to view their supplier analysis.",
    elementIds: "supplySearch",
  },
  {
    headline: "",
    text: "Or you can choose to select a company from the table to see the performance of the protein producers who supply it , listed in the 'Supplied By' column.",
    elementIds: "walkthroughAhold",
  },
  {
    headline: "",
    text: "Or you can click directly on the Supplier name to see a summary of its Index scores for 2024, along with their full assessment.",
    elementIds: "tableHeadSupplier",
  },
  {
    headline: "Want to learn more?",
    text: "Click on a company name to see the Index assessments and scores of its suppliers in more detail.",
    elementIds: "walkthroughAhold",
    noScroll: true,
    buttonText: "Go to company profile page",
    buttonLink: "/resources/companies-assessed/AD:NA/supply-chain-analysis",
  },
];
