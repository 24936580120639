import { nextTick } from "vue";

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    dataLayer?: Record<string, unknown>[];
  }
}

export const pushDataLayerEvent = (
  eventName: string,
  data: Record<string, unknown>,
) => {
  if (import.meta.server) {
    return;
  }

  nextTick(() => {
    window.dataLayer = window.dataLayer ?? [];

    window.dataLayer.push({
      event: eventName,
      ...data,
    });
  });
};
